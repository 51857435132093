import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { toastAtom } from "../../feedback/toast";
import { LoginType } from "../auth.service";

type LoginFormProps = {
    onSubmit: (data: LoginType) => Promise<unknown>;
};

type ErrorResponse = {
    message: string;
};

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
    const [error, setErrors] = useState<ErrorResponse | undefined>(undefined);
    const [, setToast] = useAtom(toastAtom);
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<LoginType>();

    return (
        <>
            <form
                onSubmit={handleSubmit((data) => {
                    setErrors(undefined);
                    return onSubmit(data)
                        .then((response) => {
                            return response;
                        })
                        .catch((er: AxiosError) => {
                            const errorData = (er.response
                                ?.data as ErrorResponse) || {
                                message: "error",
                            };
                            setToast({
                                type: "error",
                                message: errorData.message,
                            });
                            setErrors(errorData);
                        });
                })}
                className="flex flex-col"
            >
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Enter email"
                        className="input input-bordered w-full"
                        {...register("username", { required: true })}
                    />
                    {errors.username && (
                        <p className="text-error-content bg-error py-1 px-3">
                            This field is required
                        </p>
                    )}
                </div>
                <div className="mb-4">
                    <input
                        type={"password"}
                        placeholder="Password"
                        className="input input-bordered w-full"
                        {...register("password", { required: true })}
                    />
                    {errors.password && (
                        <p className="text-error-content bg-error py-1 px-3">
                            This field is required
                        </p>
                    )}
                </div>
                <p className="text-right">
                    <Link to="/forgot-password">Forgot Password</Link>
                </p>
                <div className="form-control mt-6">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary"
                    >
                        Login to continue
                    </button>
                </div>
            </form>
            {!isSubmitting && error?.message && (
                <div className="alert alert-error mt-4">
                    <>{error.message}</>
                </div>
            )}
        </>
    );
};
