import { Helmet } from "react-helmet";

import { OutsideLayout } from "../../../components/layout/OutsideLayout";
import { TuvNordLogo } from "../../../components/shared/TuvNordLogo";
import { forgotPassword, ForgotPasswordType } from "../auth.service";
import { ForgotPasswordForm } from "../components/ForgotPasswordForm";

export const ForgotPassword = () => {
    const handleSubmit = (data: ForgotPasswordType) => forgotPassword(data);

    return (
        <OutsideLayout>
            <Helmet>
                <title>Forgot Password - SIMCERT</title>
            </Helmet>
            <div className="text-center">
                <TuvNordLogo className="inline-block mb-8" />
                <p className="mb-4 text-lg">Request Reset Password</p>
            </div>
            <ForgotPasswordForm onSubmit={handleSubmit} />
        </OutsideLayout>
    );
};
