import { useAtom } from "jotai";
import { ReactNode } from "react";
import { Helmet } from "react-helmet";

import { darkModeAtom } from "../../modules/ui/ui.atom";
import { Toast } from "../shared/Toast";

type OutsideLayoutProps = {
    children: ReactNode;
};

export const OutsideLayout = ({ children }: OutsideLayoutProps) => {
    const [darkMode] = useAtom(darkModeAtom);
    const wrapperStyle = {
        backgroundImage: "url('/images//world-4Z84YBQ 1-min.png')",
    };
    return (
        <>
            <Helmet>
                <title>SIMCERT</title>
            </Helmet>
            <div
                data-theme={darkMode ? "business" : "corporate"}
                className="bg-no-repeat bg-cover bg-center bg-fixed h-screen"
                style={wrapperStyle}
            >
                <div className="container mx-auto py-8">
                    <div className="flex justify-between flex-col lg:flex-row">
                        <div className="text-center lg:text-left mb-8">
                            <h1 className="text-white text-5xl font-bold">
                                SIMcert 2.0
                            </h1>
                            <p className="text-white text-xl">
                                Certification & Audit Information <br />
                                Management System
                            </p>
                        </div>
                        <div className="px-8 lg:px-0 w-full lg:max-w-lg">
                            <div
                                className="card shadow-2xl bg-base-100"
                                data-theme="custom-corporate"
                            >
                                <div className="card-body">{children}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Toast />
            </div>
        </>
    );
};
