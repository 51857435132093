type LogoProps = {
    className?: string;
    white?: boolean;
};
export const TuvNordLogo = ({ className, white }: LogoProps) => {
    const logoColor = white ? "#FFFFFF" : "#2957A4";
    const textColor = white ? "#FFFFFF" : "#000000";
    return (
        <svg
            width="220"
            height="126"
            viewBox="0 0 220 126"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M103.646 124.963L106.515 111.268H108.336L105.478 124.963H103.646Z"
                fill={textColor}
            />
            <path
                d="M108.514 124.963L110.588 115.042H112.12L111.755 116.77C112.422 116.091 113.045 115.596 113.624 115.285C114.203 114.973 114.795 114.818 115.399 114.818C116.202 114.818 116.831 115.036 117.286 115.472C117.747 115.908 117.977 116.49 117.977 117.219C117.977 117.586 117.896 118.165 117.734 118.956L116.473 124.963H114.782L116.1 118.676C116.23 118.066 116.296 117.614 116.296 117.321C116.296 116.991 116.181 116.723 115.95 116.518C115.726 116.312 115.399 116.21 114.969 116.21C114.103 116.21 113.331 116.521 112.652 117.144C111.98 117.767 111.485 118.835 111.167 120.348L110.205 124.963H108.514Z"
                fill={textColor}
            />
            <path
                d="M125.853 123.524C124.887 124.633 123.878 125.187 122.826 125.187C121.885 125.187 121.101 124.842 120.472 124.15C119.849 123.453 119.537 122.444 119.537 121.124C119.537 119.915 119.783 118.813 120.275 117.816C120.774 116.814 121.393 116.063 122.135 115.565C122.882 115.067 123.629 114.818 124.377 114.818C125.61 114.818 126.541 115.412 127.17 116.602L128.291 111.268H129.973L127.114 124.963H125.554L125.853 123.524ZM121.219 120.806C121.219 121.497 121.288 122.042 121.425 122.441C121.562 122.839 121.795 123.173 122.125 123.44C122.455 123.702 122.851 123.833 123.312 123.833C124.078 123.833 124.772 123.434 125.395 122.637C126.23 121.578 126.647 120.27 126.647 118.713C126.647 117.929 126.441 117.315 126.03 116.873C125.619 116.431 125.102 116.21 124.479 116.21C124.075 116.21 123.704 116.3 123.368 116.48C123.038 116.661 122.708 116.969 122.377 117.405C122.054 117.835 121.78 118.383 121.555 119.05C121.331 119.716 121.219 120.301 121.219 120.806Z"
                fill={textColor}
            />
            <path
                d="M130.113 121.198C130.113 119.261 130.683 117.658 131.822 116.387C132.763 115.341 133.996 114.818 135.522 114.818C136.718 114.818 137.68 115.191 138.409 115.939C139.143 116.686 139.511 117.695 139.511 118.965C139.511 120.105 139.281 121.167 138.82 122.151C138.359 123.129 137.702 123.879 136.848 124.403C136.001 124.926 135.108 125.187 134.167 125.187C133.395 125.187 132.691 125.022 132.056 124.692C131.427 124.362 130.944 123.895 130.608 123.291C130.278 122.687 130.113 121.989 130.113 121.198ZM131.804 121.03C131.804 121.964 132.028 122.674 132.476 123.16C132.925 123.64 133.495 123.879 134.186 123.879C134.547 123.879 134.905 123.808 135.26 123.665C135.615 123.515 135.945 123.291 136.251 122.992C136.556 122.693 136.814 122.354 137.026 121.974C137.244 121.588 137.418 121.173 137.549 120.731C137.742 120.115 137.839 119.523 137.839 118.956C137.839 118.059 137.611 117.365 137.157 116.873C136.708 116.375 136.142 116.125 135.456 116.125C134.927 116.125 134.444 116.253 134.008 116.509C133.572 116.758 133.177 117.125 132.822 117.611C132.473 118.097 132.215 118.663 132.047 119.311C131.885 119.953 131.804 120.526 131.804 121.03Z"
                fill={textColor}
            />
            <path
                d="M140.464 124.963L142.538 115.042H144.07L143.706 116.77C144.372 116.091 144.995 115.596 145.574 115.285C146.153 114.973 146.745 114.818 147.349 114.818C148.152 114.818 148.781 115.036 149.236 115.472C149.697 115.908 149.927 116.49 149.927 117.219C149.927 117.586 149.846 118.165 149.685 118.956L148.423 124.963H146.732L148.05 118.676C148.18 118.066 148.246 117.614 148.246 117.321C148.246 116.991 148.131 116.723 147.9 116.518C147.676 116.312 147.349 116.21 146.919 116.21C146.054 116.21 145.281 116.521 144.602 117.144C143.93 117.767 143.435 118.835 143.117 120.348L142.155 124.963H140.464Z"
                fill={textColor}
            />
            <path
                d="M158.401 121.591L160.045 121.759C159.808 122.575 159.263 123.35 158.41 124.085C157.563 124.82 156.551 125.187 155.374 125.187C154.639 125.187 153.963 125.019 153.347 124.683C152.736 124.34 152.269 123.845 151.945 123.197C151.628 122.55 151.469 121.812 151.469 120.983C151.469 119.9 151.718 118.85 152.216 117.835C152.721 116.814 153.372 116.057 154.169 115.565C154.966 115.067 155.829 114.818 156.757 114.818C157.94 114.818 158.883 115.185 159.587 115.92C160.297 116.655 160.652 117.658 160.652 118.928C160.652 119.414 160.609 119.912 160.521 120.423H153.216C153.191 120.616 153.178 120.79 153.178 120.946C153.178 121.874 153.39 122.584 153.814 123.076C154.243 123.562 154.767 123.805 155.383 123.805C155.962 123.805 156.532 123.615 157.093 123.235C157.653 122.855 158.089 122.307 158.401 121.591ZM153.487 119.134H159.055C159.061 118.959 159.064 118.835 159.064 118.76C159.064 117.913 158.852 117.265 158.429 116.817C158.005 116.362 157.46 116.135 156.794 116.135C156.071 116.135 155.411 116.384 154.813 116.882C154.222 117.38 153.779 118.131 153.487 119.134Z"
                fill={textColor}
            />
            <path
                d="M161.923 121.572L163.614 121.469C163.614 121.955 163.688 122.369 163.838 122.712C163.987 123.054 164.261 123.335 164.66 123.552C165.065 123.77 165.535 123.879 166.071 123.879C166.818 123.879 167.379 123.73 167.752 123.431C168.126 123.132 168.313 122.78 168.313 122.375C168.313 122.083 168.201 121.806 167.976 121.544C167.746 121.282 167.182 120.962 166.285 120.582C165.395 120.196 164.825 119.925 164.576 119.769C164.159 119.514 163.844 119.215 163.632 118.872C163.421 118.523 163.315 118.125 163.315 117.676C163.315 116.892 163.626 116.219 164.249 115.658C164.872 115.098 165.744 114.818 166.865 114.818C168.11 114.818 169.057 115.107 169.705 115.686C170.359 116.259 170.698 117.016 170.723 117.957L169.069 118.069C169.045 117.471 168.833 116.997 168.434 116.649C168.036 116.3 167.472 116.125 166.743 116.125C166.158 116.125 165.703 116.259 165.379 116.527C165.055 116.795 164.894 117.085 164.894 117.396C164.894 117.707 165.034 117.981 165.314 118.218C165.501 118.38 165.983 118.629 166.762 118.965C168.057 119.526 168.873 119.968 169.21 120.292C169.745 120.809 170.013 121.438 170.013 122.179C170.013 122.671 169.86 123.154 169.555 123.627C169.256 124.101 168.795 124.48 168.173 124.767C167.556 125.047 166.827 125.187 165.987 125.187C164.841 125.187 163.866 124.904 163.062 124.337C162.259 123.77 161.879 122.849 161.923 121.572Z"
                fill={textColor}
            />
            <path
                d="M173.731 113.183L174.133 111.268H175.814L175.413 113.183H173.731ZM171.265 124.963L173.339 115.042H175.03L172.956 124.963H171.265Z"
                fill={textColor}
            />
            <path
                d="M182.279 123.73C181.694 124.234 181.13 124.605 180.588 124.842C180.046 125.072 179.467 125.187 178.851 125.187C177.935 125.187 177.197 124.92 176.637 124.384C176.076 123.842 175.796 123.151 175.796 122.31C175.796 121.756 175.92 121.267 176.169 120.843C176.425 120.414 176.761 120.071 177.178 119.816C177.596 119.554 178.106 119.367 178.711 119.255C179.09 119.18 179.81 119.121 180.869 119.078C181.934 119.034 182.697 118.922 183.157 118.741C183.288 118.28 183.354 117.897 183.354 117.592C183.354 117.2 183.21 116.892 182.924 116.667C182.531 116.356 181.958 116.2 181.205 116.2C180.495 116.2 179.913 116.359 179.458 116.677C179.009 116.988 178.683 117.433 178.477 118.013L176.767 117.863C177.116 116.879 177.667 116.125 178.421 115.602C179.181 115.079 180.137 114.818 181.289 114.818C182.516 114.818 183.487 115.11 184.204 115.696C184.752 116.132 185.026 116.698 185.026 117.396C185.026 117.925 184.948 118.539 184.792 119.236L184.241 121.703C184.067 122.487 183.979 123.126 183.979 123.618C183.979 123.929 184.048 124.378 184.185 124.963H182.475C182.382 124.639 182.317 124.228 182.279 123.73ZM182.905 119.937C182.668 120.031 182.413 120.102 182.139 120.152C181.871 120.202 181.42 120.255 180.784 120.311C179.8 120.398 179.106 120.51 178.701 120.647C178.296 120.778 177.991 120.99 177.786 121.282C177.58 121.575 177.477 121.899 177.477 122.254C177.477 122.727 177.639 123.117 177.963 123.422C178.293 123.727 178.76 123.879 179.364 123.879C179.925 123.879 180.464 123.733 180.981 123.44C181.498 123.141 181.906 122.727 182.204 122.198C182.503 121.668 182.737 120.915 182.905 119.937Z"
                fill={textColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M108.805 84.6379C109.688 80.4413 111.668 76.5543 114.544 73.3736C116.02 71.6755 117.845 70.3167 119.894 69.3892C121.943 68.4617 124.169 67.9884 126.418 68.0007C128.351 67.8184 130.294 68.236 131.98 69.1977C133.667 70.1594 135.017 71.6173 135.844 73.3736C137.299 76.9555 137.511 80.9213 136.447 84.6379C135.565 88.8348 133.585 92.7232 130.707 95.9034C129.232 97.6018 127.407 98.9614 125.358 99.8889C123.308 100.816 121.082 101.289 118.832 101.276C116.899 101.459 114.957 101.041 113.27 100.079C111.584 99.1176 110.235 97.6597 109.407 95.9034C107.953 92.3213 107.743 88.3538 108.81 84.6379H108.805ZM118.782 84.6379C117.486 90.3267 118.117 93.1719 120.676 93.1719C123.235 93.1719 125.162 90.3267 126.457 84.6379C127.751 78.9491 127.12 76.1058 124.563 76.1074C122.005 76.109 120.078 78.9523 118.782 84.6379Z"
                fill={logoColor}
            />
            <path
                d="M93.548 68.5726L98.267 85.5648C98.1995 84.5987 98.267 83.6278 98.4678 82.6804C98.5802 81.9797 99.3261 78.6843 99.3261 78.6843L101.938 68.5703H112.002L103.828 100.768H93.7828L89.0661 83.7758C89.1325 84.7411 89.0649 85.7111 88.8653 86.6579C88.7529 87.3586 88.0046 90.6563 88.0046 90.6563L85.3935 100.77H75.332L83.5041 68.5726H93.548Z"
                fill={logoColor}
            />
            <path
                d="M33.7975 91.4481L36.4693 80.0215H45.7963L42.8617 93.9553C41.3956 100.731 34.2931 102.505 29.5386 102.426C21.8281 102.3 19.627 96.4468 20.2584 93.9261L23.5211 80.0553L32.8481 80.0308L30.112 91.4574C29.99 92.0984 30.763 92.8763 31.7866 92.8763C32.2351 92.9111 32.681 92.7883 33.0489 92.5294C33.4169 92.2706 33.6834 91.8913 33.8021 91.4574L33.7975 91.4481Z"
                fill={logoColor}
            />
            <path
                d="M2.33553 68.5762L35.4861 68.5925L33.1401 78.2546H18.5745L13.1385 100.776H2.13701L7.38262 78.2955H0L2.33553 68.5762Z"
                fill={logoColor}
            />
            <path
                d="M39.2354 68.584H55.6752L57.3708 86.2336L64.6822 68.5933H76.2734L60.3217 100.779H49.9041L46.0201 78.2438H37.0762L39.2354 68.584Z"
                fill={logoColor}
            />
            <path
                d="M174.714 77.3134L170.978 92.0915C174.678 92.0915 176.682 90.8287 178.199 82.5251C178.825 79.098 177.452 77.3134 174.714 77.3134ZM155.442 88.4609C157.043 91.1895 158.392 94.0576 159.475 97.03L166.7 68.5703H176.788C185.79 68.5703 189.729 75.0449 187.806 83.8435C185.158 95.9713 180.119 100.768 169.101 100.768H150.282C148.897 96.7645 147.349 92.8906 146.289 89.2888L143.836 100.685H133.279L141.412 68.5773H153.386C155.932 68.7354 158.324 69.8547 160.077 71.7081C161.831 73.5615 162.816 76.0115 162.833 78.5629C163.057 80.8355 162.424 83.1098 161.06 84.9412C159.696 86.7727 157.698 88.0289 155.456 88.4656L155.442 88.4609ZM149.148 77.29C148.926 78.725 148.543 80.2535 148.169 81.7124H149.961C150.757 81.7339 152.592 80.6913 152.592 79.2133C152.592 78.8283 152.326 77.2755 150.917 77.2994L149.148 77.29Z"
                fill={logoColor}
            />
            <path
                d="M109.93 34.0416C114.406 23.9836 121.682 15.4261 130.889 9.39001C140.096 3.35389 150.845 0.09427 161.853 0C169.49 -6.45798e-09 177.052 1.50354 184.106 4.42584C191.161 7.34813 197.571 11.6322 202.971 17.0319C208.37 22.4316 212.653 28.841 215.575 35.896C218.497 42.9509 220 50.5128 220 58.149C219.771 71.7354 214.667 84.7868 205.616 94.9219C205.561 95.0216 205.486 95.1084 205.395 95.1765C205.304 95.2447 205.2 95.2923 205.089 95.3166C204.978 95.341 204.862 95.3416 204.751 95.3178C204.64 95.294 204.535 95.2465 204.444 95.1788C204.353 95.1112 204.278 95.0248 204.224 94.9254C204.169 94.826 204.135 94.7164 204.127 94.6031C204.118 94.4899 204.133 94.3758 204.172 94.2692C204.211 94.1625 204.272 94.0651 204.352 93.9842L204.402 93.927C212.49 83.7785 216.781 71.1239 216.531 58.149C216.531 50.9687 215.116 43.8587 212.369 37.2249C209.621 30.5911 205.594 24.5639 200.517 19.4865C195.44 14.4091 189.413 10.3806 182.78 7.63252C176.146 4.88449 169.036 3.47092 161.856 3.4706C151.362 3.3929 141.061 6.28852 132.144 11.8225C123.228 17.3564 116.06 25.3017 111.472 34.7399C111.369 34.949 111.19 35.1113 110.971 35.193C110.753 35.2747 110.511 35.27 110.295 35.1801C110.098 35.0756 109.949 34.8977 109.881 34.685C109.812 34.4723 109.83 34.2416 109.93 34.0416Z"
                fill={logoColor}
            />
        </svg>
    );
};
