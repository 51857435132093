import { Helmet } from "react-helmet";

type Props = {
    title: string;
};

export const Meta = ({ title }: Props) => (
    <Helmet>
        <title>{title} | SIMCERT</title>
    </Helmet>
);
