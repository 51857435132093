import { useAtom } from "jotai";
import { ChangeEvent } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { authAtom } from "../../modules/auth/auth.atom";
import { compactSidebarAtom } from "../../modules/ui/ui.atom";
import { HomeIcon } from "../icons/HomeIcon";
import { InboxIcon } from "../icons/InboxIcon";
import { LogoutIcon } from "../icons/LogoutIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { SimcertLogo } from "./SimcertLogo";

export const Sidebar1 = () => {
    const [compactSidebar, setCompactSidebar] = useAtom(compactSidebarAtom);
    const [auth, setAuth] = useAtom(authAtom);
    const navigate = useNavigate();

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setCompactSidebar(ev.target.checked);
    };

    const handleLogout = () => {
        setAuth(null);
        navigate("/login");
    };

    const showSettings =
        auth?.data.role && !auth.data.role.includes("Customer");

    return (
        <div className="w-[52px] bg-primary h-screen flex flex-col justify-between fixed z-10">
            <SimcertLogo />
            <div className="">
                <NavLink
                    to="/"
                    className={({ isActive }) =>
                        `btn btn-sm w-full ${
                            isActive ? "btn-active" : ""
                        } btn-ghost `
                    }
                >
                    <HomeIcon />
                </NavLink>
                {showSettings && (
                    <NavLink
                        to="/settings"
                        className={({ isActive }) =>
                            `btn btn-sm w-full ${
                                isActive ? "btn-active" : ""
                            } btn-ghost `
                        }
                    >
                        <SettingsIcon />
                    </NavLink>
                )}
                <NavLink
                    to="/notification-center"
                    className={({ isActive }) =>
                        `btn btn-sm w-full ${
                            isActive ? "btn-active" : ""
                        } btn-ghost `
                    }
                >
                    <InboxIcon />
                </NavLink>
            </div>
            <div className="text-center mb-20">
                <input
                    className="toggle toggle-sm toggle-primary"
                    type={"checkbox"}
                    checked={compactSidebar}
                    onChange={handleChange}
                />
                <button
                    className="btn btn-ghost btn-sm btn-logout"
                    type="button"
                    onClick={handleLogout}
                >
                    <LogoutIcon />
                </button>
            </div>
        </div>
    );
};
