export const removeTag = (text?: string) => {
    if (!text) return "-";
    return text.replace(/<\/?[^>]+(>|$)/g, "");
};

export const decodeHtml = (text: string) => {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
};

export const manyDays = (x: number) => {
    return x === 0 ? "0 day" : x === 1 ? "1 day" : `${x} days`;
};

export const slugify = (text: string) => {
    return text
        .replace(/[\W_]+/g, " ")
        .toLowerCase()
        .split(" ")
        .join("-");
};
