import { createBrowserRouter } from "react-router-dom";

import { SuspenseWrapper } from "./components/shared/SuspenseWrapper";
import { ForgotPassword } from "./modules/auth/pages/forgot-password";
import { Login } from "./modules/auth/pages/login";
import { ResetPassword } from "./modules/auth/pages/reset-password";
import { About } from "./pages/about";
import { Dashboard } from "./pages/dashboard";
import { ErrorPage } from "./pages/error-page";
import { Home } from "./pages/home";
import { ValidityCheck } from "./pages/validity-check";

const questionnaireSubRoute = [
    { index: true, path: "detail" },
    { path: "general-information" },
    { path: "certification-information" },
    { path: "audit-location" },
    { path: "additional-information" },
    { path: "annex" },
    { path: "mandays-calculation" },
];

export const router = createBrowserRouter([
    {
        path: "login",
        element: <Login />,
    },
    {
        path: "about",
        element: <About />,
    },
    {
        path: "validity-check",
        element: <ValidityCheck />,
    },
    {
        path: "forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "reset-password",
        element: <ResetPassword />,
    },
    {
        path: "/",
        element: <Home />,
        errorElement: <ErrorPage />,
        children: [
            {
                errorElement: <ErrorPage />,
                children: [
                    { index: true, element: <Dashboard /> },
                    {
                        path: "questionnaires",
                        children: [
                            {
                                index: true,
                                element: (
                                    <SuspenseWrapper path="modules/questionnaire/pages/list" />
                                ),
                            },
                            {
                                path: "new",
                                element: (
                                    <SuspenseWrapper path="modules/questionnaire/pages/new" />
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/questionnaire/pages/single" />
                                ),
                                children: questionnaireSubRoute.map(
                                    ({ index, path }) => ({
                                        index: index,
                                        path: !index ? path : undefined,
                                        element: (
                                            <SuspenseWrapper
                                                path={`modules/questionnaire/pages/${path}`}
                                            />
                                        ),
                                    })
                                ),
                            },
                        ],
                    },
                    {
                        path: "quotations",
                        children: [
                            {
                                index: true,
                                element: (
                                    <SuspenseWrapper path="modules/quotation/pages/list" />
                                ),
                            },
                            {
                                path: "new",
                                children: [
                                    {
                                        path: ":questionnaireId",
                                        element: (
                                            <SuspenseWrapper path="modules/quotation/pages/new" />
                                        ),
                                    },
                                ],
                            },
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/quotation/pages/single" />
                                ),
                            },
                        ],
                    },
                    {
                        path: "users",
                        children: [
                            {
                                index: true,
                                element: (
                                    <SuspenseWrapper path="modules/user/pages/list" />
                                ),
                            },
                            {
                                path: "new",
                                element: (
                                    <SuspenseWrapper path="modules/user/pages/new" />
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/user/pages/single" />
                                ),
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <SuspenseWrapper path="modules/user/pages/detail" />
                                        ),
                                    },
                                    {
                                        path: "specifications",
                                        element: (
                                            <SuspenseWrapper path="modules/user/pages/specification" />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "standards",
                        children: [
                            {
                                index: true,
                                element: (
                                    <SuspenseWrapper path="modules/standard/pages/list" />
                                ),
                            },
                            {
                                path: "new",
                                element: (
                                    <SuspenseWrapper path="modules/standard/pages/new" />
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/standard/pages/single" />
                                ),
                                children: [
                                    {
                                        index: true,
                                        element: (
                                            <SuspenseWrapper path="modules/standard/pages/detail" />
                                        ),
                                    },
                                    {
                                        path: "scopes",
                                        element: (
                                            <SuspenseWrapper path="modules/standard/pages/scope" />
                                        ),
                                    },
                                    {
                                        path: "annex",
                                        element: (
                                            <SuspenseWrapper path="modules/standard/pages/annex" />
                                        ),
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "orders",
                        children: [
                            {
                                index: true,
                                element: (
                                    <SuspenseWrapper path="modules/order/pages/list" />
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/order/pages/single" />
                                ),
                            },
                        ],
                    },
                    {
                        path: "customers",
                        element: (
                            <SuspenseWrapper path="modules/customer/pages/list" />
                        ),
                        children: [
                            {
                                path: "new",
                                element: (
                                    <SuspenseWrapper path="modules/customer/pages/new" />
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/customer/pages/single" />
                                ),
                            },
                        ],
                    },
                    {
                        path: "settings",
                        element: <SuspenseWrapper path="pages/settings" />,
                    },
                    {
                        path: "notifications",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "templates",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "budgets",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "companies",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "training-subjects",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "audit-plans",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "audit-reports",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "audit-ncs",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "audit-certificates",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "invoices",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "daily-allowances",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "notification-center",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "recertifications",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "surveillances",
                        element: <SuspenseWrapper path="pages/coming-soon" />,
                    },
                    {
                        path: "events",
                        element: (
                            <SuspenseWrapper path="modules/event/pages/list" />
                        ),
                        children: [
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/event/pages/single" />
                                ),
                            },
                        ],
                    },
                    {
                        path: "options",
                        children: [
                            {
                                index: true,
                                element: (
                                    <SuspenseWrapper path="modules/option/pages/list" />
                                ),
                            },
                            {
                                path: ":id",
                                element: (
                                    <SuspenseWrapper path="modules/option/pages/single" />
                                ),
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
