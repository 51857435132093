import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

import { ErrorDisplay } from "../components/shared/Error";

export const Dashboard = () => {
    const location = useLocation();
    const { state } = location;
    const { error } = (state || {}) as { error?: string };

    return (
        <div>
            <Helmet>
                <title>Dashboard - SIMCERT</title>
            </Helmet>
            {error && <ErrorDisplay withCard error={error} />}
            <h1>Dashboard Page</h1>
        </div>
    );
};
