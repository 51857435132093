import React, { Suspense } from "react";

type SuspenseWrapperProps = any & {
    path: string;
};

export const SuspenseWrapper = ({ path, ...rest }: SuspenseWrapperProps) => {
    const LazyComponent = React.lazy(() => import(`../../${path}`));

    return (
        <Suspense
            fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <p>Loading</p>
                    </div>
                </div>
            }
        >
            <LazyComponent {...rest} />
        </Suspense>
    );
};

export default SuspenseWrapper;
