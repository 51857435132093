import { useAtom } from "jotai";
import { ReactNode } from "react";

import { compactSidebarAtom, darkModeAtom } from "../../modules/ui/ui.atom";
import logo from "../../constants/assets/TUEV-NORD_Quality-Label_Electric-Blue.jpg";
import { Confirm } from "../shared/Confirm";
import { Meta } from "../shared/Meta";
import { Toast } from "../shared/Toast";
import { Sidebar1 } from "./Sidebar1";
import { Sidebar2 } from "./Sidebar2";

type HomeLayoutProps = {
    children: ReactNode;
};

export const HomeLayout = ({ children }: HomeLayoutProps) => {
    const [darkMode] = useAtom(darkModeAtom);
    const [compactSidebar] = useAtom(compactSidebarAtom);
    return (
        <>
            <Meta title="Welcome" />
            <div
                className=""
                data-theme={darkMode ? "custom-business" : "custom-corporate"}
            >
                <Sidebar1 />
                <Sidebar2 />
                <div
                    className={`min-h-screen bg-base-200 transition-all duration-100 ease-in  ${
                        compactSidebar
                            ? "ml-[52px] max-w-[calc(100vw_-_52px)]"
                            : "ml-[306px] max-w-[calc(100vw_-_306px)]"
                    }`}
                >
                    <div className="bg-base-100 h-8 relative shadow-2xl">
                        <p className="absolute right-8 px-8 py-2 shadow-2xl rounded-b-xl bg-[#081ECA]">
                            <img src={logo} className="w-full h-16" alt="TUV Nord Logo" />
                        </p>
                    </div>
                    <div className="p-8">{children}</div>
                </div>
            </div>
            <Confirm />
            <Toast />
        </>
    );
};
