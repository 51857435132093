import { useAtom } from "jotai";

import { useTimeout } from "../../helpers/useTimeout.hook";
import { toastAtom, ToastType } from "../../modules/feedback/toast";

export const Toast = () => {
    const [toast, setToast] = useAtom(toastAtom);

    useTimeout(() => setToast(null), toast ? 1000 * 2 : null);

    if (!toast) return null;

    let message = "";
    let type;
    let alertType = "alert-info";
    if ((toast as any).message) {
        message = (toast as ToastType).message;
        type = (toast as ToastType).type || "info";
    } else if (typeof toast === "string") {
        message = toast;
    }

    switch (type) {
        case "error":
            alertType = "alert-error";
            break;
        case "success":
            alertType = "alert-success";
            break;
        default:
            alertType = "alert-info";
            break;
    }

    return (
        <div className="toast z-[1000]">
            <div className={`alert ${alertType}`}>
                <div>
                    <span>{message}</span>
                </div>
            </div>
        </div>
    );
};
