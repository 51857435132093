export type NavigationItemType = {
    to?: string
    label: string
    children?: {
        to: string
        label: string
    }[]
}

export const getNavigationByRole = (role: string[]): NavigationItemType[] => {
    const base: NavigationItemType[] = [
        {
            to: "/",
            label: "Dashboard"
        },
        {
            label: "Questionnaire & Quotation",
            children: [
                {
                    to: "/questionnaires/new",
                    label: "New Questionnaire"
                },
                {
                    to: "/questionnaires",
                    label: "Questionnaire List"
                },
                {
                    to: "/quotations",
                    label: "Quotation List"
                }
            ]
        },
        {
            to: "/surveillances",
            label: "Surveillance List"
        },

        {
            to: "/recertifications",
            label: "Recertification List"
        },
        {
            label: "Order",
            children: [
                {
                    to: "/orders",
                    label: "Order List"
                },
                {
                    to: "/audit-plans",
                    label: "Audit Plan List"
                },
                {
                    to: "/audit-reports",
                    label: "Audit Report List"
                },
                {
                    to: "/audit-ncs",
                    label: "AoC & NC List"
                },
                {
                    to: "/audit-certificates",
                    label: "Certificate List"
                }
            ]
        },
        {
            to: "/customers",
            label: "Customers"
        },
        {
            to: "/invoices",
            label: "Invoice"
        },
        {
            to: "/daily-allowances",
            label: "Daily Allowance"
        },
    ]

    const customerAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Audit Report List',
        'AoC & NC List',
        'Certificate List',
        'Invoice',
        'Invoice List'
    ]
    const salesAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'New Questionnaire',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Certificate List',
        'Customers',
        'Schedule Matrix'
    ]
    const salesManagerAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'New Questionnaire',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Certificate List',
        'Customers',
        'Schedule Matrix'
    ]
    const applicationReviewerCoordinatorAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'Questionnaire List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Audit Report List',
        'AoC & NC List',
        'Certificate List',
        'Daily Allowance',
        'Schedule Matrix'
    ]
    const auditorAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'Questionnaire List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Audit Report List',
        'AoC & NC List',
        'Certificate List',
        'Daily Allowance',
        'Schedule Matrix'
    ]
    const bodAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Schedule Matrix'
    ]
    const schedulerAccess = [
        'Dashboard',
        'Order',
        'Order List',
        'Schedule Matrix'
    ]
    const headOfCBAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Audit Report List',
        'AoC & NC List',
        'Certificate List',
        'Daily Allowance',
        'DA Payment List',
        'Schedule Matrix'
    ]
    const deputyHeadOfCBAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Audit Report List',
        'AoC & NC List',
        'Certificate List',
        'Daily Allowance',
        'DA Payment List',
        'Schedule Matrix'
    ]
    const auditPlanOperatorAccess = [
        'Dashboard',
        'Order',
        'Order List',
        'Audit Plan List'
    ]
    const financeAccess = [
        'Dashboard',
        'Order',
        'Order List',
        'Daily Allowance',
        'DA Payment List',
        'Invoice',
        'Invoice List'
    ]
    const certificateAdminAccess = [
        'Dashboard',
        'Order',
        'Order List',
        'Certificate List'
    ]
    const superAdminAccess = [
        'Dashboard',
        'Questionnaire & Quotation',
        'New Questionnaire',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Audit Report List',
        'AoC & NC List',
        'Certificate List',
        'Daily Allowance',
        'DA Payment List',
        'Invoice',
        'Invoice List',
        'Schedule Matrix',
        'Customers',
        'Auditor Experts',
    ]
    const adminSupportAccess = [
        'Questionnaire & Quotation',
        'New Questionnaire',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List',
        'Audit Plan List',
        'Audit Report List',
        'AoC & NC List',
        'Certificate List',
        'Daily Allowance',
        'DA Payment List',
        'Invoice',
        'Invoice List',
        'Schedule Matrix',
        'Customers',
        'Auditor Experts',
    ]
    const surveillanceAdminAccess = [
        'Questionnaire & Quotation',
        'Questionnaire List',
        'Quotation List',
        'Surveillance List',
        'Recertification List',
        'Order',
        'Order List'
    ]

    let access: string[] = []

    if (role.includes('Customer')) {
        access = [...access, ...customerAccess]
    }
    if (role.includes('Sales')) {
        access = [...access, ...salesAccess]
    }
    if (role.includes('Sales Manager')) {
        access = [...access, ...salesManagerAccess]
    }
    if (role.includes('Application Reviewer Coordinator')) {
        access = [...access, ...applicationReviewerCoordinatorAccess]
    }
    if (role.includes('Auditor')) {
        access = [...access, ...auditorAccess]
    }
    if (role.includes('Board of Director')) {
        access = [...access, ...bodAccess]
    }
    if (role.includes('Scheduler')) {
        access = [...access, ...schedulerAccess]
    }
    if (role.includes('Head of CB')) {
        access = [...access, ...headOfCBAccess]
    }
    if (role.includes('Deputy Head of CB')) {
        access = [...access, ...deputyHeadOfCBAccess]
    }
    if (role.includes('Audit Plan Operator')) {
        access = [...access, ...auditPlanOperatorAccess]
    }
    if (role.includes('Super Admin')) {
        access = [...access, ...superAdminAccess]
    }
    if (role.includes('Admin Support')) {
        access = [...access, ...adminSupportAccess]
    }
    if (role.includes('Operational Manager')) {
        access = [...access, ...superAdminAccess]
    }
    if (role.includes('Finance')) {
        access = [...access, ...financeAccess]
    }
    if (role.includes('Certificate Admin')) {
        access = [...access, ...certificateAdminAccess]
    }
    if (role.includes('Surveillance Admin')) {
        access = [...access, ...surveillanceAdminAccess]
    }

    const filterIncludeInAccess = (x: NavigationItemType) => access.includes(x.label)

    return base
        .filter(filterIncludeInAccess)
        .map((x) => (
            {
                ...x,
                children: x.children ? x.children.filter(filterIncludeInAccess) : undefined
            }
        ))
}