export const toSearchParam = (param: Record<string, number | string>) => {
    const paramAsArray = Object.entries(param).map(([key, val]) => `${key}=${val}`);
    if (paramAsArray.length === 0) {
        return ''
    }
    return `?${paramAsArray.join('&')}`
}

export const toObject = (url: URLSearchParams) => {
    const entries = url.entries() as unknown as [string, string][]//returns an iterator of decoded [key,value] tuples
    const result = {} as Record<string, string>
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}

export const formatChildResoursePath = (url: string, parentId: number): string => {
    return url.replace('{parentId}', parentId.toString())
}