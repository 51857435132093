export const InboxIcon = () => (
    <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.42305 8.81653H2.36905V5.16748C2.36905 4.49741 2.63523 3.85479 3.10904 3.38098C3.58285 2.90717 4.22548 2.64099 4.89555 2.64099C5.56562 2.64099 6.20824 2.90717 6.68205 3.38098C7.15586 3.85479 7.42205 4.49741 7.42205 5.16748L7.42305 8.81653ZM8.54605 8.25549V5.16748C8.54459 4.34677 8.26689 3.55049 7.75768 2.90686C7.24846 2.26323 6.5374 1.80975 5.73905 1.61951V1.23749C5.73905 1.01418 5.65034 0.800057 5.49243 0.642151C5.33453 0.484245 5.12036 0.395508 4.89705 0.395508C4.67373 0.395508 4.45957 0.484245 4.30166 0.642151C4.14376 0.800057 4.05505 1.01418 4.05505 1.23749V1.61951C3.25618 1.80877 2.54451 2.26197 2.03513 2.90582C1.52575 3.54967 1.24844 4.3465 1.24805 5.16748V8.25549L0.123047 9.37848V9.93951H9.66705V9.37848L8.54605 8.25549ZM4.89705 11.6235C5.04452 11.6235 5.19055 11.5945 5.3268 11.538C5.46305 11.4816 5.58685 11.3989 5.69113 11.2946C5.79541 11.1903 5.87813 11.0665 5.93456 10.9302C5.991 10.794 6.02005 10.648 6.02005 10.5005H3.77205C3.77205 10.648 3.80109 10.794 3.85753 10.9302C3.91396 11.0665 3.99669 11.1903 4.10097 11.2946C4.20525 11.3989 4.32905 11.4816 4.46529 11.538C4.60154 11.5945 4.74757 11.6235 4.89505 11.6235H4.89705Z"
            fill="white"
        />
    </svg>
);
