export const LogoutIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.6757 2.23279L9.55672 3.38379C10.2427 3.81368 10.8077 4.41148 11.1981 5.12073C11.5885 5.82997 11.7915 6.62718 11.7877 7.43677C11.7894 8.69147 11.3027 9.89761 10.4307 10.7998C10.0064 11.2401 9.49775 11.5902 8.93504 11.8295C8.37233 12.0687 7.76716 12.192 7.15571 12.192C6.54427 12.192 5.9391 12.0687 5.37639 11.8295C4.81368 11.5902 4.30498 11.2401 3.88071 10.7998C3.38385 10.2857 3.00734 9.66774 2.77835 8.99048C2.54935 8.31321 2.47359 7.59352 2.55654 6.88342C2.63949 6.17332 2.87908 5.49053 3.25801 4.88428C3.63694 4.27803 4.14575 3.76351 4.74771 3.37781L3.63571 2.23279C2.89008 2.77431 2.26936 3.46958 1.81551 4.27161C1.36166 5.07363 1.08525 5.96369 1.00496 6.88171C0.924664 7.79974 1.04236 8.72426 1.35009 9.5929C1.65781 10.4615 2.14841 11.254 2.78871 11.9167C3.35411 12.5043 4.03225 12.9716 4.78254 13.2909C5.53283 13.6101 6.33983 13.7747 7.15522 13.7747C7.9706 13.7747 8.7776 13.6101 9.52788 13.2909C10.2782 12.9716 10.9563 12.5043 11.5217 11.9167C12.162 11.254 12.6526 10.4615 12.9603 9.5929C13.2681 8.72426 13.3858 7.79974 13.3055 6.88171C13.2252 5.96369 12.9488 5.07363 12.4949 4.27161C12.0411 3.46958 11.4204 2.77431 10.6747 2.23279H10.6757ZM7.92271 0.301758H6.37572V8.22876H7.91971"
            fill="white"
        />
    </svg>
);
