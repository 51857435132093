import { ErrorMessage } from "@hookform/error-message";
import { AxiosError } from "axios";
import { useAtom } from "jotai";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { toastAtom } from "../../feedback/toast";

type ResetPasswordFormType = {
    password: string;
    password_retype: string;
};

type ResetPasswordFormOutput = {
    password: string;
};

type ResetPasswordFormProps = {
    onSubmit: (data: ResetPasswordFormOutput) => Promise<unknown>;
};

type ErrorResponse = {
    message: string;
};

const formatOutput = (
    values: ResetPasswordFormType
): ResetPasswordFormOutput => {
    return {
        password: values.password,
    };
};

export const ResetPasswordForm = ({ onSubmit }: ResetPasswordFormProps) => {
    const [error, setErrors] = useState<ErrorResponse | undefined>(undefined);
    const [, setToast] = useAtom(toastAtom);
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm<ResetPasswordFormType>();

    const validatePasswordRetype = (
        data: string,
        values: ResetPasswordFormType
    ) => {
        return data === values.password || "Password doest not match";
    };

    return (
        <>
            <form
                onSubmit={handleSubmit((data) => {
                    setErrors(undefined);
                    return onSubmit(formatOutput(data))
                        .then((response) => {
                            setToast({
                                type: "success",
                                message: (response as any).message,
                            });
                            return response;
                        })
                        .catch((er: AxiosError) => {
                            const errorData = (er.response
                                ?.data as ErrorResponse) || {
                                message: "error",
                            };
                            setToast({
                                type: "error",
                                message: errorData.message,
                            });
                            setErrors(errorData);
                        });
                })}
                className="flex flex-col"
            >
                <div className="mb-4">
                    <input
                        type="password"
                        placeholder="Enter password"
                        className="input input-bordered w-full"
                        {...register("password", {
                            required: "Field is required",
                        })}
                    />
                    <ErrorMessage
                        name="password"
                        errors={errors}
                        render={({ message }) => (
                            <p className="text-error-content bg-error py-1 px-3">
                                {message}
                            </p>
                        )}
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="password"
                        placeholder="Retype password"
                        className="input input-bordered w-full"
                        {...register("password_retype", {
                            required: "Field is required",
                            validate: validatePasswordRetype,
                        })}
                    />
                    <ErrorMessage
                        name="password_retype"
                        errors={errors}
                        render={({ message }) => (
                            <p className="text-error-content bg-error py-1 px-3">
                                {message}
                            </p>
                        )}
                    />
                </div>
                <p className="text-right">
                    <Link to="/login">Login</Link>
                </p>
                <div className="form-control mt-6">
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="btn btn-primary"
                    >
                        Reset Password
                    </button>
                </div>
            </form>
            {!isSubmitting && error?.message && (
                <div className="alert alert-error mt-4">
                    <>{error.message}</>
                </div>
            )}
        </>
    );
};
