export const SimcertLogo = () => (
    <svg
        width="52"
        height="126"
        viewBox="0 0 52 126"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M0 0H52V97.662L24.52 125.908L0 115V0Z" fill="#2680EB" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.299 59.8245L43.841 59.8135C43.8455 63.3704 42.7951 66.8489 40.8227 69.8088C38.8502 72.7688 36.0442 75.0773 32.7597 76.4424C29.4751 77.8075 25.8594 78.1679 22.37 77.478C18.8806 76.7881 15.6743 75.079 13.1564 72.5667C10.6384 70.0543 8.92192 66.8516 8.22426 63.3638C7.5266 59.8759 7.87915 56.2595 9.23696 52.9719C10.5948 49.6843 12.8969 46.8731 15.8524 44.894C18.808 42.915 22.284 41.8569 25.841 41.8535L25.851 50.3955C23.9842 50.3979 22.1603 50.9536 20.6093 51.9924C19.0583 53.0313 17.8501 54.5066 17.1373 56.2319C16.4246 57.9573 16.2392 59.8552 16.6049 61.6858C16.9705 63.5164 17.8707 65.1975 19.1915 66.5166C20.5124 67.8357 22.1945 68.7336 24.0255 69.0969C25.8566 69.4602 27.7542 69.2728 29.4787 68.5579C31.2031 67.8429 32.6769 66.6329 33.7138 65.0806C34.7506 63.5283 35.3041 61.7034 35.3041 59.8367L35.299 59.8245Z"
            fill="#FAFAFA"
        />
        <path
            d="M35.5836 50.9924C35.387 50.8242 35.1591 50.6965 34.913 50.6166C34.6669 50.5367 34.4074 50.5061 34.1495 50.5268C33.8916 50.5474 33.6403 50.6188 33.41 50.7368C33.1797 50.8548 32.975 51.0171 32.8076 51.2144L26.3376 58.8024L23.1046 56.0234C22.7081 55.6832 22.1927 55.5143 21.6717 55.5541C21.1507 55.5938 20.6669 55.8389 20.3266 56.2354C19.9863 56.6319 19.8175 57.1474 19.8573 57.6683C19.897 58.1893 20.1421 58.6732 20.5386 59.0134L25.2706 63.0794C25.6281 63.3857 26.0829 63.5548 26.5536 63.5564C26.6066 63.5564 26.6536 63.5564 26.7096 63.5494C26.9678 63.5293 27.2195 63.4584 27.4503 63.3409C27.6811 63.2233 27.8865 63.0614 28.0546 62.8644L35.8086 53.7714C35.9764 53.5742 36.1036 53.3459 36.1831 53.0995C36.2626 52.853 36.2928 52.5934 36.2719 52.3353C36.251 52.0772 36.1794 51.8258 36.0613 51.5954C35.9433 51.365 35.7809 51.1601 35.5836 50.9924Z"
            fill="#FAFAFA"
        />
    </svg>
);
