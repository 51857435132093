export const UserIcon = () => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.77171 6.2717C6.30414 6.2717 6.82462 6.11382 7.26732 5.81802C7.71002 5.52222 8.05505 5.10179 8.2588 4.60989C8.46256 4.11799 8.51586 3.57671 8.41199 3.05452C8.30812 2.53232 8.05173 2.05266 7.67525 1.67618C7.29876 1.29969 6.8191 1.04329 6.29691 0.93942C5.77471 0.835549 5.23343 0.888868 4.74153 1.09262C4.24963 1.29637 3.8292 1.64141 3.5334 2.0841C3.2376 2.5268 3.07971 3.04727 3.07971 3.57969C3.07971 4.29366 3.36335 4.97838 3.86819 5.48323C4.37304 5.98808 5.05775 6.2717 5.77171 6.2717ZM5.77171 7.6177C3.97171 7.6177 0.386719 8.51771 0.386719 10.3097V11.6557H11.1567V10.3097C11.1567 8.51971 7.56871 7.6177 5.77171 7.6177Z"
            fill="black"
            fillOpacity="0.54"
        />
    </svg>
);
