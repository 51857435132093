import { useAtom } from "jotai";

import {
    confirmConfigAtom,
    confirmStateAtom,
} from "../../modules/feedback/confirm";

export const Confirm = () => {
    const [config] = useAtom(confirmConfigAtom);
    const [confirmState, setConfirmState] = useAtom(confirmStateAtom);
    return (
        <div className={`modal modal-confirm ${confirmState ? "modal-open" : ""}`}>
            <div className="modal-box">
                {config?.title && (
                    <h3 className="font-bold text-lg">{config.title}</h3>
                )}
                <p className="py-4">{config?.message || "Are you sure?"}</p>
                <p className="text-xs">(key:{config?.key})</p>
                <div className="modal-action">
                    <button
                        type="button"
                        onClick={() => setConfirmState(null)}
                        className="btn btn-ghost"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={() => setConfirmState(config?.key || null)}
                        className="btn btn-primary btn-submit"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </div>
    );
};
