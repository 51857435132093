import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import { UserData } from "../user/user.service";

export type AuthAtomType = {
    id: number;
    username: string;
    email: string;
    data: UserData;
    access: {
        access_token: string;
        access_token_expire: number;
    };
};

export type AuthDataAtomType = {};

export const authAtom = atomWithStorage<AuthAtomType | null>("auth", null);
export const accessTokenAtom = atom(
    (get) => get(authAtom)?.access.access_token
);
export const authDisplayNameAtom = atom(
    (get) => get(authAtom)?.data?.display_name || ""
);
export const authRoleAtom = atom((get) => get(authAtom)?.data?.role || []);
