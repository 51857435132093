import { useAtom } from "jotai";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

import { OutsideLayout } from "../../../components/layout/OutsideLayout";
import { authAtom } from "../auth.atom";
import { login, LoginType } from "../auth.service";
import { LoginForm } from "../components/LoginForm";
import logo from "../../../constants/assets/TUEV-NORD_Quality-Label_Electric-Blue.jpg"

export const Login = () => {
    const [auth, setAuth] = useAtom(authAtom);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (auth?.id) {
            navigate("/");
        }
    }, [auth?.id, navigate]);

    const handleLogin = (data: LoginType) =>
        login(data).then((response) => {
            setAuth(response);
            navigate(location.state?.originUrl || "/");
        });

    return (
        <OutsideLayout>
            <Helmet>
                <title>Login - SIMCERT</title>
            </Helmet>
            <div className="text-center">
                <img src={logo} className="inline-block mb-8 w-32 my-8" alt="TUV Nord Logo" />
                <p className="mb-4 text-lg">Login to your Account</p>
            </div>
            <LoginForm onSubmit={handleLogin} />
        </OutsideLayout>
    );
};
