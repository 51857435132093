import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import { OutsideLayout } from "../../../components/layout/OutsideLayout";
import { TuvNordLogo } from "../../../components/shared/TuvNordLogo";
import { toObject } from "../../../helpers/url.helper";
import { resetPassword } from "../auth.service";
import { ResetPasswordForm } from "../components/ResetPasswordForm";

export const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const initialValues = toObject(searchParams);

    if (!initialValues.token) {
        return (
            <OutsideLayout>
                <div className="alert alert-error">Missing token</div>
            </OutsideLayout>
        );
    }

    return (
        <OutsideLayout>
            <Helmet>
                <title>Reset Password - SIMCERT</title>
            </Helmet>
            <div className="text-center">
                <TuvNordLogo className="inline-block mb-8" />
                <p className="mb-4 text-lg">Request Reset Password</p>
            </div>
            <ResetPasswordForm
                onSubmit={(data) =>
                    resetPassword({
                        ...data,
                        token: (initialValues as { token: string }).token,
                    })
                }
            />
        </OutsideLayout>
    );
};
