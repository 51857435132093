import {
    faBoxArchive,
    faCalendarCheck,
    faCalendarPlus,
    faChartLine,
    faFileInvoice,
    faFileLines,
    faUserTie,
    faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAtom } from "jotai";
import { useMemo, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import {
    accessTokenAtom,
    authAtom,
    authDisplayNameAtom,
    authRoleAtom,
} from "../../modules/auth/auth.atom";
import { compactSidebarAtom } from "../../modules/ui/ui.atom";
import { CogIcon } from "../icons/CogIcon";
import { UserIcon } from "../icons/UserIcon";
import {
    getNavigationByRole,
    NavigationItemType,
} from "./helper/navigation.helper";
import { slugify } from "../../helpers/string.helper";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Icon = ({ type }: { type: IconType }) => {
    switch (type) {
        case "Questionnaire & Quotation":
            return <FontAwesomeIcon icon={faFileLines} size="xl" />;
        case "Surveillance List":
            return <FontAwesomeIcon icon={faCalendarCheck} size="xl" />;
        case "Recertification List":
            return <FontAwesomeIcon icon={faCalendarPlus} size="xl" />;
        case "Order":
            return <FontAwesomeIcon icon={faBoxArchive} size="xl" />;
        case "Customers":
            return <FontAwesomeIcon icon={faUserTie} size="xl" />;
        case "Invoice":
            return <FontAwesomeIcon icon={faFileInvoice} size="xl" />;
        case "Daily Allowance":
            return <FontAwesomeIcon icon={faWallet} size="xl" />;
        case "Dashboard":
            return <FontAwesomeIcon icon={faChartLine} size="xl" />;
        default:
            return <>x</>;
    }
};

const NavigationItem = (x: NavigationItemType) => {
    const [openSubNavigation, setOpenSubNavigation] = useState(false);
    return (
        <>
            <li className="text-xs">
                {!x.children && x.to && (
                    <NavLink
                        end
                        to={x.to}
                        className={({ isActive }) =>
                            ` ${isActive ? "active" : ""} nav-${slugify(x.label)}`
                        }
                    >
                        <Icon type={x.label as IconType} />
                        {x.label}
                    </NavLink>
                )}
                {x.children && (
                    <button
                        type="button"
                        onClick={() => setOpenSubNavigation(!openSubNavigation)}
                        className={`nav-${slugify(x.label)}`}
                    >
                        <Icon type={x.label as IconType} />
                        {x.label}
                    </button>
                )}
            </li>
            {openSubNavigation && x.children && (
                <>
                    {x.children
                        .filter((xx) => xx.to)
                        .map((xx) => (
                            <li key={xx.label} className="text-xs">
                                <NavLink
                                    end
                                    to={xx.to}
                                    className={({ isActive }) =>
                                        `pl-8 ${isActive ? "active" : ""} nav-${slugify(xx.label)}`
                                    }
                                >
                                    {xx.label}
                                </NavLink>
                            </li>
                        ))}
                </>
            )}
        </>
    );
};

export const Sidebar2 = () => {
    const [auth] = useAtom(authAtom);
    const [compactSidebar] = useAtom(compactSidebarAtom);
    const [authDisplayName] = useAtom(authDisplayNameAtom);
    const [accessToken] = useAtom(accessTokenAtom);
    const [authRole] = useAtom(authRoleAtom);
    const navigation: NavigationItemType[] = useMemo(
        () => getNavigationByRole(authRole),
        [authRole]
    );

    const profilePictureId = auth?.data.profile_picture?.[0].id;
    const imageUrl = profilePictureId
        ? `${apiBaseUrl}/v1/download/${profilePictureId}?token=${accessToken}`
        : "https://via.placeholder.com/40";

    return (
        <div
            className={`h-screen fixed ml-[52px] bg-base-100 shadow-xl overflow-auto ${
                compactSidebar ? "-left-[254px]" : "w-[254px]"
            }`}
        >
            <div className="mx-4 border-b border-base-300 mt-8">
                <div className="flex mb-2">
                    <div className="mr-4">
                        <div className="avatar">
                            <div className="w-[40px] rounded-full">
                                <img src={imageUrl} alt={authDisplayName} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-xs">
                            WELCOME TO <strong>SIMcert</strong>
                        </p>
                        <p className="text-lg">{authDisplayName}</p>
                        <p className="text-xs">{authRole?.join(" - ")}</p>
                    </div>
                </div>
                <div className="text-center block mb-2">
                    <Link to="/" className="btn btn-ghost btn-xs">
                        <CogIcon />
                    </Link>
                    <Link to="/users/me" className="btn btn-ghost btn-xs">
                        <UserIcon />
                    </Link>
                </div>
            </div>
            <ul className="menu bg-base-100 p-2 rounded-box">
                {navigation.map((x) => (
                    <NavigationItem key={x.label} {...x} />
                ))}
            </ul>
        </div>
    );
};

type IconType =
    | "Questionnaire & Quotation"
    | "Surveillance List"
    | "Recertification List"
    | "Order"
    | "Customers"
    | "Invoice"
    | "Dashboard"
    | "Daily Allowance";
