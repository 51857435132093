import { atom, useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

type ConfirmStateType = null | string;

type ConfirmConfigType = {
    key: string;
    message?: string;
    title?: string;
} | null;

export const confirmStateAtom = atom<ConfirmStateType>(null);
export const confirmConfigAtom = atom<ConfirmConfigType>(null);

export const useConfirm = (
    callback: (initialData?: any) => void,
    config: ConfirmConfigType
) => {
    const [data, setData] = useState(null);
    const [, setConfirmConfig] = useAtom(confirmConfigAtom);
    const [confirmState, setConfirmState] = useAtom(confirmStateAtom);
    const memoizedCallback = useMemo(() => callback, [callback]);

    useEffect(() => {
        if (config?.key === confirmState) {
            memoizedCallback(data);
            setConfirmState(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmState]);

    return (input: any) => {
        setData(input);
        setConfirmConfig(config);
        setConfirmState("pending");
    };
};
