export const HomeIcon = () => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.46689 10.3592V6.99121H7.83489V10.3592H9.51889V5.8172L6.15089 2.44922L2.78289 5.8172V10.3592H4.46689ZM6.15089 0.866211L11.7149 6.43021H10.6429V11.4832H6.71289V8.1152H5.58989V11.4832H1.65989V6.43021H0.587891L6.15089 0.866211Z"
            fill="white"
        />
    </svg>
);
