import { useAtom } from "jotai";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { HomeLayout } from "../components/layout/HomeLayout";
import { useTimeout } from "../helpers/useTimeout.hook";
import { authAtom } from "../modules/auth/auth.atom";

export const Home = () => {
    const [auth] = useAtom(authAtom);
    const navigate = useNavigate();
    const location = useLocation();

    const shouldLogout = (isLoggedIn: boolean) => {
        if (!isLoggedIn) {
            navigate("/login", {
                state: {
                    originUrl: `${location.pathname}${location.search}`,
                },
            });
        }
    };

    useTimeout(() => shouldLogout(!!auth?.id), 100);

    return <HomeLayout>{!!auth && <Outlet />}</HomeLayout>;
};
