type ErrorDisplayProps = {
    error?: any;
    withCard?: boolean;
};

export const ErrorDisplay = ({ error, withCard }: ErrorDisplayProps) => {
    if (!error) return null;

    if (withCard)
        return (
            <div className="alert alert-error shadow-lg mb-4">
                <pre>{JSON.stringify(error, null, "  ")}</pre>
            </div>
        );
    return <pre>{JSON.stringify(error, null, "  ")}</pre>;
};
