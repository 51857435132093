import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

export type LoginType = {
    username: string
    password: string
}

export type ForgotPasswordType = {
    email: string
}

export type ResetPasswordType = {
    password: string
    token: string
}

export const login = (data: LoginType) => axios
    .post(`${apiBaseUrl}/v1/login`, data)
    .then((response) => response.data)

export const forgotPassword = (data: ForgotPasswordType) => axios
    .post(`${apiBaseUrl}/v1/forgot-password`, data)
    .then((response) => response.data)

export const resetPassword = (data: ResetPasswordType) => axios
    .post(`${apiBaseUrl}/v1/reset-password`, data)
    .then((response) => response.data)